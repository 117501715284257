import './ButtonGroup.scss';
import { defineComponent } from 'vue';
import Button from '../Button';
export default defineComponent({
    name: 'ButtonGroup',
    components: {
        Button
    },
    props: {
        modelValue: {
            type: String,
            required: true
        },
        options: {
            type: Array,
            required: true
        }
    },
    emits: {
        change(_value) {
            return true;
        },
        ['update:modelValue'](_value) {
            return true;
        }
    },
    methods: {
        updateModel(value) {
            this.$emit('update:modelValue', value);
            this.$emit('change', value);
        }
    }
});
