import { defineComponent } from 'vue';
export default defineComponent({
    name: 'Pagination',
    props: {
        modelValue: {
            type: Number,
            required: true
        },
        pages: {
            type: Number,
            required: true
        }
    },
    computed: {
        visiblePages() {
            const pages = [];
            if (this.modelValue > 0) {
                pages.push({ value: 0, text: 'First' });
                pages.push({ value: this.modelValue - 1, text: 'Previous' });
            }
            for (let i = Math.max(this.modelValue - 3, 0); i < Math.min(this.modelValue + 3, this.pages); i++) {
                pages.push({
                    value: i,
                    text: `${i + 1}`
                });
            }
            if (this.modelValue < this.pages - 1) {
                pages.push({ value: this.modelValue + 1, text: 'Next' });
                pages.push({ value: this.pages - 1, text: 'Last' });
            }
            return pages;
        }
    },
    methods: {
        onChange(page) {
            this.$emit('update:modelValue', page);
        }
    }
});
