import { defineComponent } from 'vue';
export default defineComponent({
    name: 'CheckBox',
    props: {
        modelValue: {
            type: Boolean,
            required: true
        },
        title: {
            type: String,
            required: false
        },
        switch: {
            type: Boolean,
            required: false,
            default: false
        },
    },
    data: () => ({
        id: `flexCheckDefault${Math.random()}`
    }),
    computed: {
        formClasses() {
            const classes = ['form-check'];
            if (this.switch)
                classes.push('form-switch');
            return classes;
        }
    },
    methods: {
        onChange(event) {
            const value = event.target.checked;
            this.$emit('update:modelValue', value);
        }
    }
});
