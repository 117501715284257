import { defineComponent } from 'vue';
import { DropDown, DropDownItem } from '@/components/UI';
export default defineComponent({
    name: 'DropDownMenu',
    components: {
        DropDown,
        DropDownItem
    },
    props: {
        items: {
            type: Array,
            required: true
        }
    },
    emits: {
        clicked(_id) { return true; }
    },
    methods: {
        clicked(id) {
            this.$emit('clicked', id);
        }
    }
});
