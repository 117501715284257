import { defineComponent } from 'vue';
export default defineComponent({
    name: 'Card',
    props: {
        title: {
            type: String,
            required: false
        },
        bgVariant: {
            type: String,
            required: false,
            default: 'light'
        }
    }
});
