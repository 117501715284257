import Card from '../Card';
import { defineComponent } from 'vue';
export default defineComponent({
    name: 'Expander',
    components: {
        Card
    },
    props: {
        title: {
            type: String,
            required: true
        },
        open: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data: () => ({
        expanded: false
    }),
    mounted() {
        this.expanded = this.open;
    }
});
