import { defineComponent } from 'vue';
export default defineComponent({
    name: 'ModalBox',
    props: {
        title: {
            type: String,
            required: false,
            default: ''
        },
        closeButtonTitle: {
            type: String,
            required: false,
            default: 'Sluit'
        },
        submitButtonTitle: {
            type: String,
            required: false,
            default: ''
        },
        submitDisabled: {
            type: Boolean,
            required: false,
            default: false
        },
        size: {
            type: String,
            required: false,
            default: 'md'
        }
    },
    emits: {
        close() { return true; },
        submit() { return true; }
    },
    methods: {
        close() {
            this.$emit('close');
        },
        submit() {
            this.$emit('submit');
        }
    }
});
