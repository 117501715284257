import { defineComponent } from 'vue';
export default defineComponent({
    name: 'Button',
    props: {
        variant: {
            type: String,
            required: false,
            default: 'primary'
        },
        size: {
            type: String,
            required: false,
            default: ''
        }
    },
    emits: {
        click(_event) { return true; }
    },
    computed: {
        classes() {
            const classlist = [
                'btn',
                `btn-${this.variant}`
            ];
            if (this.size) {
                classlist.push(`btn-${this.size}`);
            }
            return classlist;
        }
    },
    methods: {
        onClick(event) {
            this.$emit('click', event);
        }
    }
});
