import { defineComponent } from 'vue';
export default defineComponent({
    name: 'DropDownItem',
    emits: {
        click(_event) { return true; }
    },
    methods: {
        onClick(event) {
            this.$emit('click', event);
        }
    }
});
