import { defineComponent } from 'vue';
export default defineComponent({
    name: 'Alert',
    props: {
        variant: {
            type: String,
            default: 'danger'
        },
        show: {
            type: Boolean,
            default: true
        }
    },
    computed: {
        classes() {
            const classList = ['alert'];
            classList.push(`alert-${this.variant}`);
            return classList;
        }
    }
});
