import { defineComponent } from 'vue';
export default defineComponent({
    name: 'Selector',
    props: {
        modelValue: {
            type: Object,
            required: true
        },
        options: {
            type: Array,
            required: true
        },
        size: {
            type: String,
            required: false,
            default: ''
        }
    },
    computed: {
        classes() {
            const classList = ['form-select'];
            if (this.size)
                classList.push(`form-select-${this.size}`);
            return classList;
        }
    },
    methods: {
        onChange(event) {
            event.stopPropagation();
            if (event.target instanceof HTMLSelectElement) {
                this.$emit('change', event.target.value);
            }
        },
        onInput(event) {
            event.stopPropagation();
            if (event.target instanceof HTMLSelectElement) {
                this.$emit('update:modelValue', event.target.value);
            }
        }
    }
});
