import { defineComponent } from 'vue';
export default defineComponent({
    name: 'Progress',
    props: {
        value: {
            type: Number,
            default: 0
        }
    }
});
